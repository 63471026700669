<template>
    <div class="resetPassword xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>个人中心</h3>
                    <p>当前位置：
                        <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>个人中心</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="personal-content">
            <div class="personal-box clearfix">
                <div class="personal-left">
                    <hd-img></hd-img>
                </div>
                <div class="personal-right">
                    <div class="personal-right-tit">
                        <i class="iconfont">&#xe62c;</i><span>患者<em>列表</em></span>
                    </div>
                    <div class="patientheard">
                        <div></div>
                        <div class="patientright">
                            <el-input v-model="keyWord" style="width: 70%" placeholder="请输入内容"  @keyup.enter.native="search">
                                <el-button slot="append" class="search-btn" type="button" icon="el-icon-search" @click="search">
                                </el-button>
                            </el-input>
                            <div style="color:#1FB7A6;font-weight: bold;margin-left: 15px;cursor: pointer;" @click="goadd"><i
                                    class="el-icon-plus"></i>发布</div>
                        </div>

                    </div>
                    <!-- 患者列表 -->
                    <el-table :data="patientList">
                        <el-table-column prop="name" label="姓名"> </el-table-column>
                        <el-table-column prop="phone" label="手机号" align="center"> </el-table-column>
                        <el-table-column prop="phone" label="性别" align="center"> 
                            <template slot-scope="data">
                                <span v-if="data.row.sex">男</span>
                                <span v-else>女</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="age" label="年龄" align="center"> </el-table-column>
                        <el-table-column prop="" label="记录" align="center"> 
                            <template slot-scope="data">{{data.row.patientDiagnose.length}}</template>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="280">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="success" plain @click="gorecord(scope.row)">复诊列表 </el-button>
                                    <el-button size="mini" type="primary" plain @click="goraddecord(scope.row)">添加记录 </el-button>
                                    <el-button size="mini" type="warning" plain @click="editPatient(scope.row)">编辑患者 </el-button>
                                </template>
                            </el-table-column>
                    </el-table>
                    <!-- <div class="patientBox">
                        <div class="patient" v-for="(item,index) in patientList" :key="index">
                            <div class="pList">
                                <div class="pListLeft">
                                    <div style="width: 70px">{{item.name}}</div>
                                    <div style="margin-left: 10px">{{item.phone}}</div>
                                    <i v-if="item.sex == true" class="el-icon-male"
                                        style="color:#15C6FF;margin-left: 10px"></i>
                                    <i v-if="item.sex == false" class="el-icon-female"
                                        style="color:#FF75D1;margin-left: 10px"></i>
                                    <div style="margin-left: 42px">{{item.age}}</div>
                                    <div style="margin-left: 110px;color:#31bdad"><i class="el-icon-document"></i>
                                        {{item.patientDiagnose.length}}</div>
                                    <div style="margin-left: 50px">{{item.patientDiagnose.length > 0 ?
                                    item.patientDiagnose[0].main : '暂无记录'}}</div>
                                </div>
                                <div>
                                    <el-dropdown>
                                        <span class="el-dropdown-link">
                                            <i class="el-icon-more" style="color:#31bdad"></i>
                                        </span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item @click.native="gorecord(item)">复诊列表</el-dropdown-item>
                                            <el-dropdown-item @click.native="goraddecord(item)">添加记录</el-dropdown-item>
                                            <el-dropdown-item @click.native="editPatient(item)">编辑患者</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>

                            </div>
                            <div style="margin-left: 40px">{{item.addTime}}</div>
                        </div>
                    </div> -->
                </div>
            </div>

        </div>
        <ft-com></ft-com>
    </div>
</template>

<script>
import ftCom from '../../components/footer'
import nhCom from "../../components/navheader";
import hdImg from "../../components/headerImg";
import { patientMain } from "../../components/patienTmanagement/patienTmanagement";
export default {
    name: "resetPassword",
    components: {
        ftCom,
        nhCom,
        hdImg
    },
    data() {
        var patient = new patientMain(this.TokenClient, this.Services.Authorization)
        return {
            patientDomain: patient,
            labelPosition: 'right',
            resetPassword: {
                oldPwd: '',
                newPwd: '',
                confirmPwd: ''
            },
            uploader: null,
            file: '',
            patientType: 1,// 固定值
            organitionId: 0,// 固定值
            keyWord: '',
            pageIndex: 1,
            userInfo: {},
            patientList: [],
            visible: false
        };
    },
    mounted() {
        this.getList()
    },
    methods: {
        search() {
            this.pageIndex = 1;
            this.getList();
        },
        gorecord(e) {
            this.$router.push({
                path: '/patienTmanagement/recordList',
                query: {
                    patientId: e.id
                }
            })
        },
        goraddecord(e) {
            this.$router.push({
                path: '/patienTmanagement/addRecord',
                query: {
                    patientId: e.id
                }
            })
        },
        goadd() {
            this.$router.push('/patienTmanagement/addPatient')
        },
        editPatient(e) {
            this.$router.push({
                path: '/patienTmanagement/addPatient',
                query: {
                    patientId: e.id
                }
            })
        },
        getList() {
            var _this = this;
            _this.userInfo = this.$store.state.localData.userInfo
            console.log(_this.keyWord)
            _this.patientDomain.getPatientList(this.patientType, _this.userInfo.organizationId, _this.userInfo.id, '', '', _this.keyWord, _this.pageIndex, function (data) {
                _this.patientList = data.data.results
                for (var i = 0; i < _this.patientList.length; i++) {
                    var f = _this.patientList[i].addTime.split('T')
                    _this.patientList[i].addTime = f[0] + ' ' + f[1].slice(0, 8)
                }

            }, function (error) {
                console.log(error)
            })
        }
    }
}
</script>

<style scoped>
@import "../../assets/css/footer.css";

.banner {
    width: 100%;
    height: 160px;
    background: url("../../assets/img/news_banner.jpg") center no-repeat;
}

.patientheard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 21px 0 15px 0;
    border-bottom: 1px dashed #CCCCCC;
}

.patientright {
    display: flex;
    align-items: center;
}

.patientBox {
    padding: 21px 42px;
    color: #808080
}

.patient {
    padding: 21px 0 15px 0;
    border-bottom: 1px dashed #CCCCCC;
}

.pList {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pListLeft {
    display: flex;
    align-items: center;
}

.banner-wrap {
    position: relative;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
}

.banner-text {
    float: left;
}

.banner-text h3 {
    padding-top: 35px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text p {
    font-size: 14px;
    color: #ffffff;
    margin-top: 20px;
}

.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

/**/
.personal-content {
    background: #f7f7f7;
    padding: 40px 0;
}

.personal-box {
    width: 1200px;
    margin: 0 auto;
}

.personal-left {
    float: left;
}

.personal-right {
    float: right;
    width: 900px;
    background: #FFFFFF;
    box-sizing: border-box;
}

.personal-right-tit {
    border-bottom: 1px solid #e5e5e5;
    padding: 20px;
}

.personal-right-tit .iconfont {
    color: #04a290;
    font-size: 24px;
    margin-right: 5px;
}

.personal-right-tit span {
    display: inline-block;
    font-size: 24px;
    font-weight: 700;
    color: #333333;
}

.personal-right-tit span em {
    font-style: normal;
    color: #04a290;
}

.personal-right>form {
    padding: 30px 15px;
    width: 460px;
}
</style>
<style>
.personal-center {
    display: none;
}
</style>
